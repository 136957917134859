import React, { useState } from 'react';
import { DatePicker, Select, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useGetSparkMemoryMetrics } from 'hooks/sparkDriver';
import { filtersKeys } from 'types/types';
import './MetricsLayout.scss';
import SpinnerChidori from 'components/SpinnerChidori/SpinnerChidori';
import { useGetNumberOfRunningJobs } from 'hooks/numberOfRunningJobs';
import TimeSeriesChart from './SparkDriverMemoryChart/TimeSeriesChart';
import { useGetDiskMetrics } from 'hooks/diskMetrics';

dayjs.extend(utc);
dayjs.extend(timezone);

interface SelectedRange {
  fromDate: number;
  toDate: number;
}

const MetricsLayout: React.FC = () => {
  const intl = useIntl();

  const defaultFromDate = dayjs().subtract(24, 'hour');
  const defaultToDate = dayjs();

  const [selectedRange, setSelectedRange] = useState<SelectedRange>({
    fromDate: defaultFromDate.unix(),
    toDate: defaultToDate.unix(),
  });

  const [formatedRange, setFormatedRange] = useState(
    `${
      filtersKeys.CREATED_AT_DATE_RANGE
    }: ${defaultFromDate.toISOString()} to ${defaultToDate.toISOString()}`
  );

  const [dateRangePicker, setDateRangePicker] = useState<
    [dayjs.Dayjs, dayjs.Dayjs]
  >([defaultFromDate, defaultToDate]);

  const [selectedMetric, setSelectedMetric] = useState('sparkDriverMemory'); // State for selected component

  const { data: memoryUsageAndLimit, isLoading: isMemoryLoading } =
    useGetSparkMemoryMetrics({
      fromDate: selectedRange.fromDate,
      toDate: selectedRange.toDate,
      formatedRange,
    });

  const { data: runningJobs, isLoading: isNumberOfJobsLoading } =
    useGetNumberOfRunningJobs({
      fromDate: selectedRange.fromDate,
      toDate: selectedRange.toDate,
      formatedRange,
    });

  const { data: diskMetrics, isLoading: isDiskMetricsLoading } =
  useGetDiskMetrics({
    fromDate: selectedRange.fromDate,
    toDate: selectedRange.toDate,
    formatedRange,
  });

  const bytesToMB = (bytes: number) => bytes / 1024 ** 2;


  const memoryLimitsInMB =
    memoryUsageAndLimit?.MemoryLimits.map(bytesToMB) || [];
  const memoryUsedInMB = memoryUsageAndLimit?.MemoryUsed.map(bytesToMB) || [];
  const memoryTimeData = memoryUsageAndLimit?.TimeData;


  const bytesToGB = (bytes: number) => bytes / 1024 ** 3;
  const diskCapacityInGB =
    diskMetrics?.Capacity.map(bytesToGB) || [];
  const diskUsageInGB = diskMetrics?.Usage.map(bytesToGB) || [];
  const diskTimeData = diskMetrics?.TimeData;

  const numberOfRunningJob = runningJobs?.RunningJobs || [];
  const jobsTimeData = runningJobs?.TimeData;

  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past15Minutes' }),
      value: [dayjs().subtract(15, 'minute'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past1Hour' }),
      value: [dayjs().subtract(1, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past6Hours' }),
      value: [dayjs().subtract(6, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past12Hours' }),
      value: [dayjs().subtract(12, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past24Hours' }),
      value: [dayjs().subtract(24, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past7Days' }),
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past14Days' }),
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past30Days' }),
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past60Days' }),
      value: [dayjs().subtract(60, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past90Days' }),
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  const handleDateChange = (
    values: any,
    [startDate, endDate]: [string, string]
  ) => {
    const isFieldValueSet = !!(startDate && endDate);
    const today = dayjs().startOf('day');
    const endDateDayjs = dayjs(endDate).startOf('day');

    if (isFieldValueSet) {
      const fromDate = dayjs(values[0]);

      const toDate =
        endDateDayjs.isSame(today, 'day') || endDateDayjs.isAfter(today, 'day')
          ? dayjs()
          : dayjs(endDate).endOf('day');

      const fromDateUnix = fromDate.unix();
      const toDateUnix = toDate.unix();

      const fromDateModifiedISO = fromDate.toISOString();
      const toDateModifiedISO = toDate.toISOString();

      setSelectedRange({
        fromDate: fromDateUnix,
        toDate: toDateUnix,
      });

      setFormatedRange(
        `${filtersKeys.CREATED_AT_DATE_RANGE}: ${fromDateModifiedISO} to ${toDateModifiedISO}`
      );

      setDateRangePicker([fromDate, toDate]);
    }
  };

  const handleMetricChange = (value: string) => {
    setSelectedMetric(value);
  };

  const hasMemoryData =
    memoryLimitsInMB.length > 0 || memoryUsedInMB.length > 0;

  const hasDiskData =
  diskCapacityInGB.length > 0 || diskUsageInGB.length > 0;
  const hasRunningJobsData = numberOfRunningJob.length > 0;

  const renderChartContent = () => {
    if (
      (selectedMetric === 'sparkDriverMemory' && isMemoryLoading) ||
      (selectedMetric === 'numberOfRunningJobs' && isNumberOfJobsLoading)
    ) {
      return <SpinnerChidori />;
    }

    if (
      !hasMemoryData &&
      !isMemoryLoading &&
      selectedMetric === 'sparkDriverMemory'
    ) {
      return (
        <div>No Spark driver memory data available for the selected range</div>
      );
    }

    if (
      !hasMemoryData &&
      !isMemoryLoading &&
      selectedMetric === 'numberOfRunningJobs'
    ) {
      return <div>No running jobs available for the selected range</div>;
    }

    if (
      !hasDiskData &&
      !isDiskMetricsLoading &&
      selectedMetric === 'diskMetrics'
    ) {
      return <div>No disk metrics available for selected range</div>;
    }

    if (
      !isMemoryLoading &&
      hasMemoryData &&
      selectedMetric === 'sparkDriverMemory'
    ) {
      return (
        <TimeSeriesChart
          key="sparkDriverMemoryUsage"
          chartType="smooth"
          chartTitle={intl.formatMessage({
            id: 'metrics.sparkDriverMemoryUsage',
          })}
          yAxisFormatter={(val: number) => `${val.toFixed(1)} MB`}
          yAxisTitle={`${intl.formatMessage({ id: 'metrics.memory' })} (MB)`}
          toolTipFormatter={(val: number) => `${val} MB`}
          seriesData={[
            {
              name: intl.formatMessage({ id: 'metrics.allocatedMemory' }),
              data: memoryLimitsInMB,
            },
            {
              name: intl.formatMessage({ id: 'metrics.usedMemory' }),
              data: memoryUsedInMB,
            },
          ]}
          timeData={memoryTimeData}
        />
      );
    }

    if (
      !isNumberOfJobsLoading &&
      hasRunningJobsData &&
      selectedMetric === 'numberOfRunningJobs'
    ) {
      return (
        <TimeSeriesChart
          key="numberOfRunningJobs"
          chartType="stepline"
          chartTitle={intl.formatMessage({
            id: 'metrics.numberOfRunningJobs',
          })}
          yAxisFormatter={(val: number) => `${val.toFixed(0)} Jobs`}
          yAxisTitle={`${intl.formatMessage({
            id: 'metrics.numberOfRunningJobs',
          })} (Jobs)`}
          toolTipFormatter={(val: number) => `${val} Jobs`}
          seriesData={[
            {
              name: intl.formatMessage({ id: 'metrics.runningJob' }),
              data: numberOfRunningJob,
            },
          ]}
          timeData={jobsTimeData}
        />
      );
    }

    if (
      !isDiskMetricsLoading &&
      hasDiskData &&
      selectedMetric === 'diskMetrics'
    ) {
      return (
        <TimeSeriesChart
          key="diskMetrics"
          chartType="smooth"
          chartTitle={intl.formatMessage({
            id: 'metrics.chidoriDisk',
          })}
          yAxisFormatter={(val: number) => `${val.toFixed(1)} GB`}
          yAxisTitle={`${intl.formatMessage({ id: 'metrics.disk' })} (GB)`}
          toolTipFormatter={(val: number) => `${val} GB`}
          seriesData={[
            {
              name: intl.formatMessage({ id: 'metrics.disk.capacity' }),
              data: diskCapacityInGB,
            },
            {
              name: intl.formatMessage({ id: 'metrics.disk.usage' }),
              data: diskUsageInGB,
            },
          ]}
          timeData={diskTimeData}
        />
      );
    }
  };

  return (
    <section className="metrics-layout">
      <div className="metrics-layout__filters">
        <Select
          defaultValue="sparkDriverMemory"
          onChange={handleMetricChange}
          className="metrics-layout__filters-select"
          options={[
            {
              value: 'sparkDriverMemory',
              label: intl.formatMessage({
                id: 'metrics.sparkDriverMemoryUsage',
              }),
            },
            {
              value: 'numberOfRunningJobs',
              label: intl.formatMessage({ id: 'metrics.numberOfRunningJobs' }),
            },
            {
              value: 'diskMetrics',
              label: intl.formatMessage({ id: 'metrics.diskMetrics' }),
            },
          ]}
        />

        <DatePicker.RangePicker
          className="metrics-layout__filters-range"
          presets={rangePresets}
          defaultPickerValue={dateRangePicker}
          value={dateRangePicker}
          onChange={handleDateChange}
        />
      </div>

      <div className="metrics-layout__chart">{renderChartContent()}</div>
    </section>
  );
};

export default MetricsLayout;
