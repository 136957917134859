import React, { useContext, useState } from 'react';
import './NavigationLayout.scss';
import { Layout } from 'antd';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import UserProfile from 'components/UserProfile/UserProfile';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useIntl } from 'react-intl';
import JobsMenuIcon from 'assets/icons/job-menu-icon.svg';
import ChidoriConnectMenuIcon from 'assets/icons/connect-icon.svg';
import SparkStarIcon from 'assets/icons/spark-star.svg';
import ChidoriIconName from 'assets/icons/chidori-logo-new-one-line.svg';
import LanguageChanger from 'components/LanguageChanger/LanguageChanger';
import { AppContext } from 'contexts/AppContext';
import useSignedURL from 'hooks/useSignedURL';
import { createSparkHistoryServerLink } from 'utils/cluster';
import { useGetClusterInfo } from 'hooks/cluster';
import { BarChartOutlined } from '@ant-design/icons';
import useChidoriRoutes from 'hooks/useChidoriRoutes';
import { menuItemKey } from 'types/types';
import clsx from 'clsx';
import Settings from 'components/Settings/Settings';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  disabled: boolean = false,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  } as MenuItem;
}

const { Content } = Layout;

const NavigationLayout: React.FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { rootURL } = useContext(AppContext);
  const { isSignedURL } = useSignedURL();
  const { menuUrlMapping, defaultMenuKey } = useChidoriRoutes();
  const { clusterName } = useContext(AppContext);

  const { data: clusterInfo } = useGetClusterInfo({ clusterName });
  const sparkHistoryServerLink = clusterInfo?.Url
    ? createSparkHistoryServerLink(clusterInfo?.Url)
    : '';

  const [collapsed, setCollapsed] = useState(false);

  const items: MenuProps['items'] = [
    getItem(
      <label className="navigation-layout__menu-item-wrapper">
        {intl.formatMessage({ id: 'sidebarMenu.jobs' })}
      </label>,
      menuItemKey.JOBS,
      <img src={JobsMenuIcon} alt="jobs" />
    ),

    ...(isSignedURL
      ? []
      : [
          getItem(
            <label className="navigation-layout__menu-item-wrapper">
              {intl.formatMessage({ id: 'chidoriConnect.connect' })}
            </label>,
            menuItemKey.CHIDORI_CONNECT,
            <img
              src={ChidoriConnectMenuIcon}
              alt="Spark History Server"
              className="navigation-layout__connect-icon"
            />
          ),
          getItem(
            <label className="navigation-layout__menu-item-wrapper">
              {intl.formatMessage({ id: 'sidebarMenu.metrics' })}
            </label>,
            menuItemKey.METRICS,
            <BarChartOutlined style={{ color: 'blue' }} />
          ),
        ]),
  ];

  const onMenuItemClick: MenuProps['onClick'] = (e) => {
    if (!isSignedURL) {
      const navigationUrl = menuUrlMapping.get(e.key as menuItemKey);
      if (navigationUrl) {
        navigate(navigationUrl);
      }
    }
  };

  return (
    <Layout className="navigation-layout__wrapper">
      <Sider
        className="navigation-layout__sider"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="light"
        width={256}>
        <section
          className={clsx('navigation-layout__sider-logo', {
            collapsed: collapsed,
          })}>
          <Link to={rootURL}>
            <img src={ChidoriIconName} alt="Chidori Icon" />
          </Link>
        </section>
        <Menu
          onClick={onMenuItemClick}
          mode="inline"
          items={items}
          selectedKeys={[defaultMenuKey]}
          className="navigation-layout__menu-wrapper"
        />

        {!collapsed ? (
          <section className="navigation-layout__sider-actions">
            {!isSignedURL && (
              <section className="navigation-layout__spark-history-link">
                <section>
                  <img
                    src={SparkStarIcon}
                    alt="Spark History Server"
                    className="navigation-layout__spark-history-link-icon"
                  />
                </section>

                <Link
                  to={sparkHistoryServerLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <label className="navigation-layout__spark-history-link-label">
                    {intl.formatMessage({
                      id: 'sidebarMenu.sparkHistoryServer',
                    })}
                  </label>
                </Link>
              </section>
            )}
            <LanguageChanger />
            {!isSignedURL && <Settings />}
            <UserProfile />
          </section>
        ) : null}
      </Sider>
      <Layout className="navigation-layout__main">
        <Content className="navigation-layout__content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default NavigationLayout;
