import axios, { AxiosResponse } from 'axios';
import { ChartResposnse, JobsResponse, SparkJobStatuses } from 'types/types';
import { API_URL } from 'utils/http';

type SchemasAndTablesData = { Schemas: string[]; Tables: string[] };
export async function getSchemasAndTables() {
  try {
    const res = await axios.get<SchemasAndTablesData>(`${API_URL}/data`);
    return res;
  } catch (error) {
    throw error;
  }
}

type getAllJobsPaginatedProps = {
  pageNumber: number;
  pageSize: number;
  expires?: string;
  signature?: string;
  clusterName?: string;
  prefix?: string;
  JOB_ID?: string;
  SCHEMA?: string[];
  MV?: string[];
  STATUS?: SparkJobStatuses[];
  CREATED_AT_DATE_RANGE?: { start_date?: string; end_date?: string };
  sortBy?: string | null;
  sortOrder?: string | null;
};
export async function getAllJobsPaginated({
  pageNumber,
  pageSize,
  expires,
  signature,
  clusterName,
  prefix,
  JOB_ID,
  SCHEMA,
  MV,
  STATUS,
  CREATED_AT_DATE_RANGE,
  sortBy,
  sortOrder,
}: getAllJobsPaginatedProps) {
  try {
    const res = await axios.get<JobsResponse>(`${API_URL}/fetch`, {
      params: {
        page_number: pageNumber,
        page_size: pageSize,
        ...(expires ? { expires } : {}),
        ...(signature ? { signature } : {}),
        ...(clusterName ? { cluster_name: clusterName } : {}),
        ...(prefix ? { prefix } : {}),
        ...(JOB_ID ? { job_id: JOB_ID } : {}),
        ...(SCHEMA ? { schema: SCHEMA } : {}),
        ...(MV ? { table: MV } : {}),
        ...(STATUS?.length ? { status: STATUS } : {}),
        ...(!!CREATED_AT_DATE_RANGE?.start_date
          ? { start_date: CREATED_AT_DATE_RANGE.start_date }
          : {}),
        ...(!!CREATED_AT_DATE_RANGE?.end_date
          ? { end_date: CREATED_AT_DATE_RANGE.end_date }
          : {}),
        ...(sortBy
          ? {
              sort_by: sortBy,
              ...(sortOrder ? { sort_order: `${sortOrder}ing` } : {}),
            }
          : {}),
      },
      paramsSerializer: {
        indexes: null, // by default: false, Used to accept repeated same param key with different values (status)
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getStatusCounts({
  clusterName,
}: {
  clusterName: string;
}) {
  try {
    type responseType = {
      Cancelled: number;
      Failed: number;
      In_Queue: number;
      Running: number;
      Succeeded: number;
      Timed_Out: number;
      Unknown: number;
    };
    const res = await axios.get<responseType>(
      `${API_URL}/status-counts?cluster_name=${clusterName}`
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getJobLogs({
  jobId,
  clusterName,
  mV,
  table,
  signature,
  expires,
}: {
  jobId: string;
  clusterName: string;
  mV?: string;
  table?: string;
  signature?: string;
  expires?: string;
}) {
  try {
    return axios.get<string>(`${API_URL}/get-logs`, {
      params: {
        job_id: jobId,
        cluster_name: clusterName,
        ...(mV ? { mv: mV } : {}),
        ...(table ? { table } : {}),
        ...(signature ? { signature } : {}),
        ...(expires ? { expires } : {}),
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function getSignedURL({
  clusterName,
  tableName,
  schema,
}: {
  clusterName: string;
  tableName: string;
  schema: string;
}) {
  type responseType = {
    signedURL: string;
  };
  try {
    return axios.get<responseType>(`${API_URL}/signed-url`, {
      params: {
        mv: tableName,
        cluster_name: clusterName,
        schema,
      },
    });
  } catch (error) {
    throw error;
  }
}

export async function getJobMetrics({ sparkJobId }: { sparkJobId: string }) {
  try {
    const res = await axios.get<Record<string, unknown>[]>(
      `${API_URL}/metrics`
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function killJob({
  clusterName,
  sparkJobID,
}: {
  clusterName: string;
  sparkJobID: string;
}) {
  try {
    const res = await axios.post<AxiosResponse<any>>(`${API_URL}/cancel-job`, {
      ClusterName: clusterName,
      job_id: sparkJobID,
    });
    return res;
  } catch (error) {
    throw error;
  }
}

export async function getChartJobsData({
  tableName,
  schemaName,
  clusterName,
  expires,
  signature,
  prefix,
  STATUS,
  CREATED_AT_DATE_RANGE,
}: {
  tableName: string;
  schemaName: string;
  clusterName: string;
  expires?: string;
  signature?: string;
  prefix?: string;
  STATUS?: SparkJobStatuses[];
  CREATED_AT_DATE_RANGE?: {
    start_date?: string;
    end_date?: string;
  };
}) {
  try {
    const res = await axios.get<ChartResposnse>(`${API_URL}/chart`, {
      params: {
        hard_limit: 20,
        table: tableName,
        schema: schemaName,
        cluster: clusterName,
        ...(expires ? { expires } : {}),
        ...(signature ? { signature } : {}),
        ...(prefix ? { prefix } : {}),
        ...(STATUS?.length ? { status: STATUS } : {}),
        ...(!!CREATED_AT_DATE_RANGE?.start_date
          ? { start_date: CREATED_AT_DATE_RANGE.start_date }
          : {}),
        ...(!!CREATED_AT_DATE_RANGE?.end_date
          ? { end_date: CREATED_AT_DATE_RANGE.end_date }
          : {}),
      },
      paramsSerializer: {
        indexes: null, // by default: false, Used to accept repeated same param key with different values (status)
      },
    });
    return res.data;
  } catch (error) {}
}

export async function getJobProperties({
  jobId,
  clusterName,
}: {
  jobId: string;
  clusterName: string;
}) {
  try {
    const res = axios.get<string>(`${API_URL}/properties`, {
      params: {
        job_id: jobId,
        cluster_name: clusterName,
      },

    })
    return res;
    ;
  } catch (error) {
    throw error;
  }
}
export async function getJobCode({
  jobId,
  clusterName,
  mvLanguage,
}: {
  jobId: string;
  clusterName: string;
  mvLanguage:string;
}) {
  try {
    const res = axios.get<string>(`${API_URL}/code`, {
      params: {
        job_id: jobId,
        cluster_name: clusterName,
        mv_language: mvLanguage,
      },

    })
    return res;
    ;
  } catch (error) {
    throw error;
  }
}

