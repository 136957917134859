import { message } from 'antd';
import { QueryServerKeys } from 'QueryServerKeys';
import { useQuery } from 'react-query';
import { getDiskMetrics } from 'services/diskMetrics';

export function useGetDiskMetrics({
  fromDate,
  toDate,
  formatedRange,
}: {
  fromDate: number;
  toDate: number;
  formatedRange: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.METRICS.GET_DISK, formatedRange],
    async () => {
      const res = await getDiskMetrics(fromDate, toDate);

      return res?.data;
    },
    {
      onError(err) {
        message.error('Error fetching disk metrics');
      },
      refetchOnWindowFocus: false,
    }
  );

  return queryOptions;
}
