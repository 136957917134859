import { JobStatus } from 'components/StatusTag/StatusTag';

export type vendorTypes =
  | 'Kubernetes'
  | 'Databricks'
  | 'GCP Cloud Dataproc'
  | 'Azure HDInsight';

export type SparkJobStatuses =
  | JobStatus.Succeeded
  | JobStatus.Cancelled
  | JobStatus.Failed
  | JobStatus.In_Queue
  | JobStatus.Timed_Out
  | JobStatus.Running;

export enum MVLanguageEnum {
  'Spark Python' = 'Spark Python',
  'Spark SQL/PostgreSQL' = 'Spark SQL/PostgreSQL',
  'Spark R' = 'Spark R',
  'Spark Scala' = 'Spark Scala',
}

export type JobsResponseItem = {
  Key: string;
  ClusterName: string;
  ClusterUrl: string;
  ClusterPrefix: string;
  SparkJobID: string;
  SparkExitCode: string;
  Vendor: string;
  CreatedAt: Date;
  UpdatedAt: Date;
  DequeuedAt: Date;
  Duration: number;
  QueueingTime: number;
  SparkAppID: string;
  SparkAppName: string;
  MVLanguage: MVLanguageEnum;
  SchemaName: string;
  TableName: string;
  IncortaGUID: string;
  SparkJobStatus: SparkJobStatuses;
  TotalExecutorCount?: number;
};

export type JobsResponse = {
  SparkStatusDto: JobsResponseItem[];
  TotalDuration: number;
  NumberOfJobs: number;
  Limit: number;
  TotalPages: number;
  CurrentPage: number;
};

export type ChartResposnse = {
  SparkStatusDto: JobsResponseItem[];
  TotalJobs: number;
};

export type SparkDriverMemoryResponse = {
  TimeData: string[];
  MemoryUsed: number[];
  MemoryLimits: number[];
};

export type DiskMetrics = {
  TimeData: string[];
  Usage: number[];
  Capacity: number[];
};

export type NumberOfJobsResponse = {
  TimeData: string[];
  RunningJobs: number[];
};

export enum headerTypesEnum {
  'JOBS' = 'jobs',
  'METRICS' = 'metrics',
  'CONNECT' = 'connect',
}

export enum pathKeys {
  ROOT = 'ROOT',
  SIGN_IN = 'SIGN_IN',
  TABLE = 'TABLE',
  JOB_INFO = 'JOB_INFO',
  ALL_CLUSTERS = 'ALL_CLUSTERS',
  ALL_CLUSTERS_SIGN_IN = 'ALL_CLUSTERS_SIGN_IN',
  ALL_CLUSTERS_TABLE = 'ALL_CLUSTERS_TABLE',
  ALL_CLUSTERS_JOB_INFO = 'ALL_CLUSTERS_JOB_INFO',
  METRICS = 'METRICS',
  CHIDORI_CONNECT = 'CHIDORI_CONNECT',
}

export const AVAILABLE_PATHS = new Map([
  [pathKeys.ROOT, '/cluster/:clusterName'],
  [pathKeys.CHIDORI_CONNECT, '/cluster/:clusterName/chidoriConnect'],
  [pathKeys.SIGN_IN, '/cluster/:clusterName/signin'],
  [pathKeys.TABLE, '/cluster/:clusterName/table/:tableName'],
  [pathKeys.JOB_INFO, '/cluster/:clusterName/jobinfo/:jobId'],
  [pathKeys.METRICS, '/cluster/:clusterName/metrics'],
]);

export enum menuItemKey {
  JOBS = 'jobs',
  METRICS = 'metrics',
  CHIDORI_CONNECT = 'connect',
}

export type StatusesCounts = {
  all: number;
  Succeeded: number;
  Cancelled: number;
  Failed: number;
  In_Queue: number;
  Running: number;
};

export enum filtersKeys {
  JOB_ID = 'JOB_ID',
  CREATED_AT_DATE_RANGE = 'CREATED_AT_DATE_RANGE',
  STATUS = 'STATUS',
  MV = 'MV',
  SCHEMA = 'SCHEMA',
}

export type AppliedFilter = Partial<
  | Record<filtersKeys.JOB_ID, string>
  | Record<filtersKeys.STATUS, JobStatus[]>
  | Record<
      filtersKeys.CREATED_AT_DATE_RANGE,
      { start_date?: string; end_date?: string }
    >
  | Record<filtersKeys.MV, string[]>
  | Record<filtersKeys.SCHEMA, string[]>
>;

export const seachableTablePageSizeOptions = [10, 20, 30, 40, 50];

export enum ColumnSortEnum {
  'ascend' = 'ascend',
  'descend' = 'descend',
}

export type ColumnSort = ColumnSortEnum.ascend | ColumnSortEnum.descend | null;
