import { useMutation, useQueries, useQuery } from 'react-query';
import { QueryServerKeys } from 'QueryServerKeys';
import {
  getAllJobsPaginated,
  getChartJobsData,
  getJobCode,
  getJobLogs,
  getJobMetrics,
  getJobProperties,
  getSchemasAndTables,
  getSignedURL,
  getStatusCounts,
  killJob,
} from 'services/jobs';
import { filtersKeys } from 'types/types';
import { useContext } from 'react';
import { AppContext } from 'contexts/AppContext';
import { useOnErrorLogout } from './user';

export function useSchemasAndTables() {
  const queryOptions = useQuery(
    [QueryServerKeys.LISTS.GET_SCHEMAS_AND_TABLES],
    async () => {
      const res = await getSchemasAndTables();
      return res.data;
    },
    {
      refetchInterval: 1000 * 60,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );
  return queryOptions;
}

export function useGetJobLogs({
  clusterName,
  jobId,
  mV,
  table,
  signature,
  expires,
}: {
  clusterName: string;
  jobId: string;
  mV?: string;
  table?: string;
  signature?: string;
  expires?: string;
}) {
  const queryOptions = useMutation(
    async () => {
      return getJobLogs({
        clusterName,
        jobId,
        ...(mV ? { mV } : {}),
        ...(table ? { table } : {}),
        signature,
        expires,
      });
    },
    {
      mutationKey: [QueryServerKeys.JOBS.GET_JOB_LOGS, clusterName, jobId],
      onError(error: any) {
        throw error;
      },
    }
  );

  return queryOptions;
}

export function useGetSignedURL({
  clusterName,
  tableName,
  schema,
}: {
  clusterName: string;
  tableName: string;
  schema: string;
}) {
  const queryOptions = useMutation(
    async () => {
      return getSignedURL({ clusterName, tableName, schema });
    },
    {
      mutationKey: [
        QueryServerKeys.JOBS.GET_JOB_SIGNED_URL,
        clusterName,
        tableName,
      ],
      onError(error: any) {
        throw new Error(error);
      },
    }
  );

  return queryOptions;
}

export function useGetJobMetrics({ jobId }: { jobId: string }) {
  const queryOptions = useQuery(
    [QueryServerKeys.JOBS.GET_JOB_METRICS, jobId],
    () => getJobMetrics({ sparkJobId: jobId }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      select(res) {
        const totalRunTime = res.data
          ?.map((d: any) => d?.['Task Metrics']['Executor Run Time'])
          .reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + currentValue,
            0
          ); // ms

        const totalCPUTime = res.data
          ?.map((d: any) => d?.['Task Metrics']['Executor CPU Time'])
          .reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + currentValue,
            0
          ); // nano sec

        const shuffleTime = res.data
          ?.map(
            (d: any) =>
              d?.['Task Metrics']['Shuffle Write Metrics']['Shuffle Write Time']
          )
          .reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + currentValue,
            0
          ); // nano sec

        const gcTime = res.data
          ?.map((d: any) => d?.['Task Metrics']['JVM GC Time'])
          .reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + currentValue,
            0
          ); // nano sec

        const otherTime =
          totalRunTime -
          totalCPUTime / 1000000 -
          shuffleTime / 1000000 -
          gcTime; // ms

        return { totalRunTime, totalCPUTime, shuffleTime, gcTime, otherTime };
      },
    }
  );
  return queryOptions;
}

export function useKillJob() {
  const queryOptions = useMutation(
    async ({
      clusterName,
      sparkJobID,
    }: {
      clusterName: string;
      sparkJobID: string;
    }) => {
      return await killJob({
        clusterName,
        sparkJobID,
      });
    },
    {
      async onSuccess() {
        // await queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA);
      },
      onError(error: any) {
        throw new Error(error);
      },
    }
  );

  return queryOptions;
}

const commonRefetchProps = {
  refetchInterval: 1000 * 60, // 60 sec
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false,
};

export function useStatusCounts({
  clusterName,
  isEnabled,
}: {
  clusterName: string;
  isEnabled: boolean;
}) {
  const onErrorLogout = useOnErrorLogout({ clusterName });
  const queryOptions = useQuery(
    [QueryServerKeys.JOBS.GET_STATUS_COUNTS, clusterName],
    () => getStatusCounts({ clusterName }),
    {
      enabled: isEnabled,
      ...onErrorLogout,
      ...commonRefetchProps,
    }
  );
  return queryOptions;
}

export function useJobsAndJobsStatuses({
  clusterName,
  pageNumber,
  pageSize,
  isJobsEnabled,
  isJobsStatusesEnabled,
  isSignedURL = false,
  expires,
  signature,
  prefix,
  tableName,
  schemaName,
  filters,
}: {
  clusterName: string;
  pageNumber: number;
  pageSize: number;
  isJobsEnabled: boolean;
  isJobsStatusesEnabled: boolean;
  isSignedURL?: boolean;
  expires?: string;
  signature?: string;
  prefix?: string;
  tableName?: string;
  schemaName?: string;
  filters?: any;
}) {
  const { currentAppliedFilters, sortBy, sortOrder } = useContext(AppContext);
  const onErrorLogout = useOnErrorLogout({ clusterName });

  function getSortOptions() {
    const sortOptions = {
      ...(Object.keys(filtersKeys).length && sortBy
        ? {
            sortBy,
            ...(sortOrder ? { sortOrder } : {}),
          }
        : {}),
    };
    return sortOptions;
  }

  return useQueries([
    {
      queryKey: [QueryServerKeys.JOBS.GET_STATUS_COUNTS, clusterName],
      queryFn: () => getStatusCounts({ clusterName }),
      enabled: isJobsStatusesEnabled,
      ...onErrorLogout,
      ...commonRefetchProps,
    },
    {
      queryKey: [
        !tableName && !schemaName
          ? QueryServerKeys.JOBS.GET_ALL_JOBS_PAGINATED
          : QueryServerKeys.JOBS.GET_TABLE_DETAILS_JOBS_PAGINATED,
        clusterName,
        pageNumber,
        pageSize,
        ...(filters ? [filters] : []),
        ...(sortBy ? [sortBy] : []),
        ...(sortOrder ? [sortOrder] : []),
      ],
      queryFn: async () => {
        const res = await getAllJobsPaginated({
          pageNumber,
          pageSize,
          ...(isSignedURL && expires ? { expires } : {}),
          ...(isSignedURL && signature ? { signature } : {}),
          ...(isSignedURL && clusterName ? { clusterName } : {}),
          ...(isSignedURL && prefix ? { prefix } : {}),
          ...(isSignedURL && schemaName
            ? { [filtersKeys.SCHEMA]: [schemaName] }
            : {}),
          ...(isSignedURL && tableName
            ? { [filtersKeys.MV]: [tableName] }
            : {}),
          ...currentAppliedFilters,
          ...getSortOptions(),
        });
        return res?.data;
      },
      enabled: isJobsEnabled,
      ...onErrorLogout,
      ...commonRefetchProps,
    },
  ]);
}

export function useGetChartJobsData({
  tableName,
  schemaName,
  clusterName,
  isSignedURL = false,
  expires,
  signature,
  prefix,
  filters,
}: {
  tableName: string;
  schemaName: string;
  clusterName: string;
  isSignedURL?: boolean;
  expires?: string;
  signature?: string;
  prefix?: string;
  filters?: any;
}) {
  const { currentAppliedFilters } = useContext(AppContext);
  const queryOptions = useQuery(
    [
      QueryServerKeys.JOBS.GET_JOBS_CHART,
      clusterName,
      schemaName,
      ...(filters ? [filters] : []),
    ],
    async () => {
      const res = await getChartJobsData({
        tableName,
        schemaName,
        clusterName,
        ...(isSignedURL && expires ? { expires } : {}),
        ...(isSignedURL && signature ? { signature } : {}),
        ...(isSignedURL && prefix ? { prefix } : {}),
        ...currentAppliedFilters,
      });
      return res?.SparkStatusDto;
    },
    {
      refetchInterval: 1000 * 60,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );
  return queryOptions;
}

export function useGetJobProperties({
  clusterName,
  jobId,
}: {
  clusterName: string;
  jobId: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.JOBS.GET_JOB_PROPERTIES, clusterName, jobId],
    async () => {
      const res = await getJobProperties({ clusterName, jobId });
      return res?.data;
    },
    {
      onError: (error: any) => {
        console.error('Error fetching job properties:', error);
      },
    }
  );

  return queryOptions;
}
export function useGetJobCode({
  clusterName,
  jobId,
  mvLanguage,
}: {
  clusterName: string;
  jobId: string;
  mvLanguage: string;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.JOBS.GET_JOB_CODE, clusterName, jobId,mvLanguage],
    async () => {
      const res = await getJobCode({ clusterName, jobId ,mvLanguage});
      return res?.data;
    },
    {
      onError: (error: any) => {
        console.error('Error fetching job properties:', error);
      },
    }
  );

  return queryOptions;
}