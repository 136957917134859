import React, { useContext, useEffect, useState } from 'react';
import './JobsTable.scss';
import { AppContext } from 'contexts/AppContext';
import { useJobsAndJobsStatuses, useSchemasAndTables } from 'hooks/jobs';
import { JobsResponseItem, seachableTablePageSizeOptions } from 'types/types';
import { FormattedMessage } from 'react-intl';
import { Button, Popover, Result, Tooltip } from 'antd';
import SpinnerChidori from 'components/SpinnerChidori/SpinnerChidori';
import { InfoCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import SearchableTable, {
  DATA_VIEW,
} from 'components/reusables/SearchableTable/SearchableTable';
import { ColumnsType } from 'antd/es/table';
import TableDateCellRenderer from 'components/JobsTable/TableCellRenderers/DateCellRenderer/DateCellRenderer';
import StatusCellRenderer from 'components/JobsTable/TableCellRenderers/StatusCellRenderer/StatusCellRenderer';
import { prepareDuration, formatDurationToHMS } from 'utils/date';
import { JobStatus } from 'components/StatusTag/StatusTag';
import { Link } from 'react-router-dom';
import JobRowInfoCard from 'components/JobsTable/JobRowInfoCard/JobRowInfoCard';
import HoverActions from './HoverActions/HoverActions';
import useSignedURL from 'hooks/useSignedURL';
import dayjs from 'dayjs';
import useAntdSortColumns from 'hooks/useAntdSortColumns';
import useAntdTableChange from 'hooks/useAntdTableChange';
import { useGetClusterInfo } from 'hooks/cluster';
import LanguageCellRenderer from './TableCellRenderers/LanguageCellRenderer/LanguageCellRenderer';
import DurationCellRenderer from './TableCellRenderers/DurationCellRenderer/DurationCellRenderer';

function JobsTable() {
  const { clusterName, pathname, currentAppliedFilters } =
    useContext(AppContext);

  const { data: clusterInfo } = useGetClusterInfo({ clusterName });
  const clusterURLFromClusterInfo = clusterInfo?.Url || '';

  const { isSignedURL } = useSignedURL();
  const isJobsEnabled = !!clusterName && !isSignedURL;
  const isJobsStatusesEnabled = !!clusterName && !isSignedURL;

  const [jobIDFilter, setJobIDFilter] = useState('');
  const [tableFilter, setTableFilter] = useState<string[]>([]);
  const [schemaFilters, setSchemaFilters] = useState<string[]>([]);
  const [dateRangePicker, setDateRangePicker] = useState<
    dayjs.Dayjs[] | null[]
  >([]);
  const [jobStatusFilters, setJobStatusFilters] = useState<JobStatus[]>([]);

  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const [page, setPage] = useState(1);

  const [
    { refetch: refetchJobsStatus },
    {
      data: jobsData,
      isLoading: isJobsLoading,
      error: jobsError,
      refetch: refetchJobs,
      isFetching: isJobsFetching,
      dataUpdatedAt: jobsUpdatedAt,
    },
  ] = useJobsAndJobsStatuses({
    clusterName: clusterName as string,
    isJobsEnabled,
    isJobsStatusesEnabled,
    pageSize: limit,
    pageNumber: page,
    ...(Object.keys(currentAppliedFilters)?.length
      ? { filters: currentAppliedFilters }
      : {}),
  });

  async function refetchJobsAndStatuses() {
    refetchJobs();
    refetchJobsStatus();
  }

  const jobs = jobsData?.SparkStatusDto || [];
  const current = jobsData?.CurrentPage;
  const total = jobsData?.NumberOfJobs;

  const {
    data: schemasAndTablesData,
    isError: hasSchemasAndTablesError,
    isLoading: isSchemasAndTablesLoading,
  } = useSchemasAndTables();

  const { getSortOptions } = useAntdSortColumns();

  const [durations, setDurations] = useState(jobs.map(() => 0));

  useEffect(() => {
    const intervals = jobs.map((job, index) => {
      function doSet() {
        const currentTime = new Date().getTime();
        const createdAtTime = new Date(job.CreatedAt).getTime();
        const duration = currentTime - createdAtTime; // milliseconds
        setDurations((prevDurations) => {
          const newDurations = [...prevDurations];
          newDurations[index] = duration;
          return newDurations;
        });
      }
      doSet();
      const intervalId = setInterval(() => {
        doSet();
      }, 3000); // Update every 5 seconds

      return () => clearInterval(intervalId);
    });

    return () => intervals.forEach((doClear) => doClear());
  }, [jobs]);

  const columns: ColumnsType<JobsResponseItem> = [
    {
      title: <FormattedMessage id="columns.job" />,
      dataIndex: 'SparkJobID',
      key: 'SparkJobID',
      width: '180px',
      render(value, { SparkJobID }, index) {
        return <div className="jobs-table__job-id"> {SparkJobID} </div>;
      },
      ...getSortOptions({ fieldName: 'SparkJobID' }),
    },
    {
      title: <FormattedMessage id="columns.status" />,
      key: 'SparkJobStatus',
      dataIndex: 'SparkJobStatus',
      width: '100px',
      render(value, { SparkJobStatus }, index) {
        return <StatusCellRenderer status={SparkJobStatus} />;
      },
    },
    {
      title: () => {
        return (
          <div className="jobs-table__creation-time-column-header">
            <FormattedMessage id="columns.creationTime" />
          </div>
        );
      },
      key: 'CreatedAt',
      dataIndex: 'CreatedAt',
      width: '120px',
      render(value, { CreatedAt }, index) {
        return <TableDateCellRenderer originalValue={CreatedAt.toString()} />;
      },
      ...getSortOptions({ fieldName: 'CreatedAt' }),
    },
    {
      title: <FormattedMessage id="columns.duration" />,
      dataIndex: 'Duration',
      key: 'Duration',
      width: '160px',
      render(valueInMSec, { SparkJobStatus }, index) {
        let durationMSec;
        if (
          SparkJobStatus === JobStatus.Running ||
          SparkJobStatus === JobStatus.In_Queue
        ) {
          durationMSec = durations[index];
        } else {
          durationMSec = valueInMSec;
        }
        const duration = prepareDuration(durationMSec);
        return <DurationCellRenderer duration={duration} />;
      },
    },
    {
      title: <FormattedMessage id="columns.queueingTime" />,
      dataIndex: 'QueueingTime',
      key: 'QueueingTime',
      width: '120px',
      render(valueInMSec, record, index) {
        valueInMSec = valueInMSec <= 0 ? 0 : valueInMSec;
        if (valueInMSec === 0) {
          return <> - </>;
        }

        const duration = prepareDuration(valueInMSec);
        return formatDurationToHMS({ duration, shorthand: true });
      },
    },
    {
      title: <FormattedMessage id="columns.tableName" />,
      dataIndex: 'TableName',
      key: 'TableName',
      width: '170px',
      render(
        value,
        {
          TableName: tableName,
          SparkAppID: jobId,
          Vendor: vendor,
          SchemaName: schemaName,
          MVLanguage: mVLanguage,
          SparkAppName: appName,
          ClusterPrefix: prefix,
          SparkJobStatus: jobStatus,
          ClusterUrl: clusterUrl,
        },
        index
      ) {
        return (
          <Link
            to={`/cluster/${clusterName}/table/${tableName}`}
            className="jobs-table__table-name-link"
            state={{
              jobId,
              vendor,
              currentJobInfo: {
                tableName,
                schemaName,
                mVLanguage,
                appName,
                prefix,
                jobStatus,
                clusterUrl,
              },
            }}>
            {tableName}
          </Link>
        );
      },
    },
    {
      title: <FormattedMessage id="columns.schema" />,
      dataIndex: 'SchemaName',
      key: 'SchemaName',
      width: '170px',
      render(value, index) {
        return (
          <span className="jobs-table__schema-name-cell">
            <article className="jobs-table__schema-name-cell-value">
              {value}
            </article>
          </span>
        );
      },
    },
    {
      title: <FormattedMessage id="actions.info.column.language" />,
      key: 'MVLanguage',
      width: '350px',
      render(
        value,
        {
          SparkJobID,
          Vendor,
          MVLanguage,
          SparkJobStatus,
          SparkAppID,
          ClusterPrefix,
          TableName,
          SchemaName,
          TotalExecutorCount,
        }
      ) {
        const isDownloadLogsBtnDisabled = SparkJobStatus === JobStatus.In_Queue;
        return (
          <span className="jobs-table__mv-language-cell">
            <section className="jobs-table__hoverable-actions">
              <LanguageCellRenderer MVLanguage={MVLanguage} />
              <HoverActions
                tableName={TableName}
                sparkJobID={SparkJobID}
                isDownloadLogsBtnDisabled={isDownloadLogsBtnDisabled}
                clusterUrl={clusterURLFromClusterInfo}
                sparkJobStatus={SparkJobStatus}
                sparkAppID={SparkAppID}
                clusterName={clusterName}
                schemaName={SchemaName}
                clusterPrefix={ClusterPrefix}
              />
            </section>
            <Popover
              placement="top"
              content={
                <JobRowInfoCard
                  sparkJobID={SparkJobID}
                  vendor={Vendor}
                  mVLanguage={MVLanguage}
                  totalExecutorCount={TotalExecutorCount}
                />
              }
              arrow={false}>
              <InfoCircleOutlined className="jobs-table__actions-info" />
            </Popover>
          </span>
        );
      },
    },
  ];

  const { onChange } = useAntdTableChange();

  if (isJobsLoading) {
    return <SpinnerChidori />;
  } else if (jobsError) {
    return (
      <Result
        status="error"
        title={<FormattedMessage id="somethingWentWrong" />}
        extra={[
          <Button type="primary" onClick={() => refetchJobsAndStatuses()}>
            Retry
          </Button>,
        ]}
      />
    );
  } else {
    return (
      <div className="jobs-table__wrapper">
        <section className="jobs-table__title">
          <FormattedMessage id="table.title" />
          <section className="jobs-table__title-sync">
            <label>
              <FormattedMessage
                id="table.lastSync"
                values={{
                  time: jobsUpdatedAt
                    ? format(new Date(jobsUpdatedAt), 'hh:mm:ss a')
                    : '',
                }}
              />
            </label>
            <Tooltip title={<FormattedMessage id="table.refreshTitle" />}>
              <Button
                icon={<RedoOutlined rotate={-90} />}
                className="jobs-table__title-sync-btn"
                onClick={() => refetchJobsAndStatuses()}
                loading={isJobsFetching}
              />
            </Tooltip>
          </section>
        </section>

        <SearchableTable<JobsResponseItem>
          showAllJobs={true}
          dataSource={jobs}
          dataSourceTotalCount={total ?? 0}
          columns={columns}
          loading={isJobsLoading}
          pagination={{
            pageSize: limit,
            current,
            total,
            showSizeChanger: true,
            pageSizeOptions: seachableTablePageSizeOptions,
            onShowSizeChange: (_, size) => {
              setLimit(size);
            },
            onChange(newPage) {
              setPage(newPage);
            },
          }}
          rowKey={'SparkJobID'}
          onChange={onChange}
          currentView={DATA_VIEW.TABLE_VIEW}
          jobIDFilter={jobIDFilter}
          setJobIDFilter={setJobIDFilter}
          jobStatusFilters={jobStatusFilters}
          setJobStatusFilters={setJobStatusFilters}
          dateRangePicker={dateRangePicker}
          setDateRangePicker={setDateRangePicker}
          tableFilter={tableFilter}
          setTableFilter={setTableFilter}
          schemaFilters={schemaFilters}
          setSchemaFilters={setSchemaFilters}
          schemas={schemasAndTablesData?.Schemas || []}
          tables={schemasAndTablesData?.Tables || []}
          scroll={{ x: 1132 }}
        />
      </div>
    );
  }
}

export default JobsTable;
