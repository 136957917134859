import axios from 'axios';
import { DiskMetrics } from 'types/types';
import { API_URL } from 'utils/http';

export async function getDiskMetrics(fromDate: number, toDate: number) {
  try {
    const res = await axios.get<DiskMetrics>(
      `${API_URL}/metrics/disk`,
      {
        params: {
          startTimestamp: fromDate,
          endTimestamp: toDate,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Error fetching disk metrics:', error);
    throw error;
  }
}
